// @mui
import { styled } from '@mui/material/styles';
import { Stack, Link, Container, Typography } from '@mui/material';
// guards
import AdminAuthGuard from '../guards/AdminAuthGuard';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/login';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <AdminAuthGuard>
      <Page title="login">
        <Container maxWidth="xs">
          <ContentStyle>
            <Stack sx={{ mb: 3 }}>
              <Typography variant="h4">
                <Link underline="none" sx={{ fontFamily: (theme) => theme.typography.subFontFamily }}>
                  DIO
                </Link>
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Staff Only
              </Typography>
            </Stack>
            <LoginForm />
          </ContentStyle>
        </Container>
      </Page>
    </AdminAuthGuard>
  );
}
