import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, Button, TextField, InputAdornment } from '@mui/material';
import Iconify from '../Iconify';

RHFFileUploadField.propTypes = {
  name: PropTypes.string,
}

export default function RHFFileUploadField({ name, item, label='', link=false, openfile=null, helperText='' }) {
  const { control, setValue } = useFormContext();

  const [fileName, setFileName] = useState('');

  const handleChangeFile = (e) => {
    if (e.target.files.length) {
      // file 업로드 하기
      //

      // form 으로 전달할 값은 여기서 세팅 (uploaded file id)
      setValue(name, e.target.files[0]);
      // setValue(name, `${e.target.files[0].name}-id`);

      // textfield 에 보여줄 값은 여기서 세팅 (file name)
      setFileName(e.target.files[0].name);
    }
  }

  const conditionalInputProps = () => {
    if (!item?.file?.id || !link || item?.file?.name !== fileName) {
      return {}
    } else {
      return {
        startAdornment: <InputAdornment position="start">
        <IconButton size="small" component="label" onClick={openfile}>
          <Iconify icon={'eva:link-2-fill'} />
        </IconButton>
      </InputAdornment>
      }
    }
  }

  useEffect(() => {
    if (item && item.file) {
      setValue(name, item.file);
      setFileName(item.file.name);
    }
  }, [item]);

  return (
    <Controller 
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          label={label}
          value={fileName}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">
              <Button
                variant="contained"
                component="label"
                edge="end"
              >
                파일 선택
                <input id={name} style={{ display: 'none' }} type='file' name={name} onChange={handleChangeFile} />
              </Button>
            </InputAdornment>,
            ...conditionalInputProps()
          }}
          error={!!error} helperText={error?.message ? error.message : helperText}
        />
      )}
    />
  )
}