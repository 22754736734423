import PropTypes from 'prop-types';
import { useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_LOGIN, PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AdminAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AdminAuthGuard({ children }) {
  const { push, pathname, query } = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    if (user && !user.isAdmin) {
      window.location.href = "https://www.dio.so/";
      return ;
    } else if (user && user.isAdmin) {
      if (pathname === PATH_LOGIN) {
        push({
          pathname: PATH_DASHBOARD.root,
          query
        })
      } else {
        push({
          pathname,
          query
        });
      }
    } else if ((!user || !user.isAdmin) && pathname !== PATH_LOGIN) {
      push({
        pathname: PATH_LOGIN,
        query
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
}
